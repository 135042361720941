import React from 'react';
import { useFetch } from '../../../hooks/useFetch';
import { Menu } from '@mui/material';
import OUSelection from './ouSelection/ouSelection';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import cx from "classnames";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const TreeComponent = ({ selectedOu, setSelectedOu }) => {
    const { data, isFetching } = useFetch('api/mfl/treeview')
    let treeNodes = []
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function changeKey(obj, eliminar) {
        if (eliminar)
            delete obj['organisationUnitGroups']

        obj['label'] = obj['name']
        obj['value'] = obj['id']
    }

    if (data)
        if (data.organisationUnits) {
            changeKey(data.organisationUnits[0])
            data.organisationUnits[0].children.map((val) => {
                changeKey(val)
                val.children.map((val2) => {
                    changeKey(val2)
                    val2.children.map((val3) => {
                        changeKey(val3, true)
                    })
                })
            })

            treeNodes = flattenTree({
                name: "", children: [data.organisationUnits[0]]
            })
        }


    const onCheck = (v) => {
        console.log(v,'clicked')
        setSelectedOu({ label: v.name, childrens: v.children, id: v.id });
        handleClose()
    };

    const cancelSelection = () => {
        setSelectedOu("");
    }

    return (
        <>
            <OUSelection isFetching={isFetching} cancelSelection={cancelSelection} handleClickMenu={handleClickMenu} selectedOu={selectedOu} />
            <Menu
                style={{ borderRadius: "100px" }}
                onClose={handleClose}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div style={{ margin: "5px", height: "70vh"}} >
                    <TreeView
                        data={treeNodes}
                        aria-label="Checkbox tree"
                        propagateSelect
                        propagateSelectUpwards
                        togglableSelect
                        nodeRenderer={({
                            element,
                            isBranch,
                            isExpanded,
                            isSelected,
                            isHalfSelected,
                            getNodeProps,
                            level,
                            handleSelect,
                            handleExpand,
                        }) => {
                            return (
                                <div
                                    {...getNodeProps({ onClick: handleExpand })}
                                    style={{ marginLeft: 40 * (level - 1) }}
                                >
                                    {isBranch && <ArrowIcon isOpen={isExpanded} />}
                                    <CheckBoxIconComponent
                                        className="checkbox-icon"
                                        onClick={(e) => {
                                            handleSelect(e);
                                            onCheck(element)
                                            e.stopPropagation();
                                        }}
                                        variant={
                                            isHalfSelected ? "some" : isSelected ? "all" : "none"
                                        }
                                    />
                                    <span className="name">{element.name}</span>
                                </div>
                            );
                        }}
                    />
                </div>
            </Menu>
        </>
    );
}

const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
        baseClass,
        { [`${baseClass}--closed`]: !isOpen },
        { [`${baseClass}--open`]: isOpen },
        className
    );
    return <ChevronRightIcon className={classes} />;
};

const CheckBoxIconComponent = ({ variant, ...rest }) => {
    switch (variant) {
        case "all":
            return <CheckBoxIcon {...rest} />;
        case "none":
            return <CheckBoxOutlineBlankIcon {...rest} />;
        case "some":
            return <IndeterminateCheckBoxIcon {...rest} />;
        default:
            return null;
    }
};
export { TreeComponent };