import { formaterToIsoDate } from "../../../../utils/services/dateFormater"

export function getDates({ sDate, eDate }) {
    let params = ""

    function formatDates(value) {
        if (value === "0") {
            return "01"
        } else if (value.length < 2) {
            return "0" + value
        } else {
            return value
        }
    }

   function last30Days() {
    let params = "";

    const today = new Date();

    for (let a = 1; a <= 30; a++) {
        const data = new Date(today);
        data.setDate(today.getDate() - a);

        const year = data.getFullYear();
        const month = formatDates((data.getMonth() + 1).toString()); // meses vão de 0 a 11, então adiciona 1
        const day = formatDates(data.getDate().toString());

        const dateString = `${year}${month}${day}`;

        params += dateString + ";";
    }

    // Remove o último ";"
    return params.slice(0, -1);
}


    function LastYears() {
        params = ""
        var currentYear = new Date().getFullYear()
        let startYear = 2010

        while (startYear <= currentYear) {
            let year = startYear++
            params += year.toString() + ";"
        }
        return params.substring(0, params.length - 1)
    }

    function generatePeriod(range) {
        if (range) {
            return "period=" + LastYears()
        } else {
            if (!sDate || !eDate) {
                return "period=" + LastYears()
            } else {
                return params = `startDate=${formaterToIsoDate(sDate)}&endDate=${formaterToIsoDate(eDate)}`
            }
        }
    }

    return { generatePeriod, last30Days }
}