const modules = [
  // {
  //   shortName: "MFL",
  //   displayName: "Lista Mestre de Unidades Sanitárias",
  //   icon: "local_hospital",
  //   necessaryRole:'undefined'
  // },
  {
    shortName: "Covid-19",
    displayName: "Monitoria da Emissão dos Certificado de Vacinação contra Covid-19",
    description: "Emissão dos Certificado de Vacinação contra Covid-19",
    icon: "coronavirus",
    necessaryRole: "monitoring_covid",
    route: "covid",
    bgColor: "#7ebbe4",
  },
  {
    shortName: "SIRCEV - Óbitos",
    displayName: "Módulo Hospitalar (MISAU) e SIRCEV - Óbitos",
    description: "Emissão dos Certificado de Vacinação contra Covid-19",
    icon: "sick",
    necessaryRole: "monitoring_sircev",
    route: "sircev",
    bgColor: "#ea5b92",
  },
  {
    shortName: "SIRCEV - Nascimentos",
    displayName: "Módulo Hospitalar (MISAU) e SIRCEV - Nascimentos",
    description: "Emissão dos Certificado de Vacinação contra Covid-19",
    icon: "sick",
    necessaryRole: "monitoring_sircev",
    route: "sircev_nascimentos",
    bgColor: "#ea5b92",
  },
  {
    shortName: "CMAM",
    displayName: "Interoperabilidade entre o SIS-MA e Ferramenta Central da CMAM",
    description: "Emissão dos Certificado de Vacinação contra Covid-19",
    icon: "vaccines",
    necessaryRole: "monitoring_cmam",
    route: "cmam",
    bgColor: "#19bb9d",
  },
  {
    shortName: "UMM",
    displayName: "Módulo de Gestão de Utilizadores",
    description: "Emissão dos Certificado de Vacinação contra Covid-19",
    icon: "people",
    necessaryRole: "monitoring_umm",
    route: "umm",
    bgColor: "#fa8035",
  },
  {
    shortName: "MFL - External",
    displayName: "Portal Público para o acesso á Lista Mestre das Unidades Sanitárias",
    description: "Emissão dos Certificado de Vacinação contra Covid-19",
    icon: "location_city",
    necessaryRole: "mfl_external",
    route: "mfl",
    bgColor: "#fa8035",
  },
  {
    shortName: "MFL - Internal",
    displayName: "Gestão das Unidades Sanitárias do Sistema Nacional de Saúde",
    description: "Emissão dos Certificado de Vacinação contra Covid-19",
    icon: "domain",
    necessaryRole: "mfl_internal",
    route: "mfl-internal",
    bgColor: "#364971",
  },
  {
    shortName: "SUS",
    displayName: "Módulo de  Monitoria de Sicncronização de Unidades Sanitárias",
    description: "Módulo de Monitoria de Sincronização de Unidades Sanitárias",
    icon: "domain",
    necessaryRole: "monitoring_sircev",
    route: "sus",
    bgColor: "#ea9b92",
  },
  {
    shortName: "eIMD-VBG",
    displayName: "Instrumento Electrónico de Medição de Desempenho de Serviços de Cuidados Pós Violência Baseada no Género ",
    description: "Instrumento Electrónico de Medição de Desempenho de Serviços de Cuidados Pós Violência Baseada no Género ",
    icon: "domain",
    necessaryRole: "monitoring_sircev",
    route: "imd-vbg",
    bgColor: "#D39D4F",
  },
  {
    shortName: "SIIMM",
    displayName: "Sistema Integrado de Armazenamento de Informação de dados da Malária",
    description: "Sistema Integrado de Armazenamento de Informação de dados da Malária",
    icon: "domain",
    necessaryRole: "monitoring_sircev",
    route: "siim",
    bgColor: "#ea9b92",
  },
  // {
  //   shortName: "ECSA-HC",
  //   displayName: "Plataforma regional de VE",
  //   icon: "assessment",
  //   necessaryRole:'monitoring_ecsa'
  // },
];

export { modules };
